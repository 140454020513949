import { template as template_9dbf72c74d5d48f3bc597bb60c8f3fd0 } from "@ember/template-compiler";
const FKLabel = template_9dbf72c74d5d48f3bc597bb60c8f3fd0(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
