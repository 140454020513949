import { template as template_dd3cc71efb2748cfbfd773e2b9977e6b } from "@ember/template-compiler";
const FKText = template_dd3cc71efb2748cfbfd773e2b9977e6b(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
