import { template as template_4e558aaaf47e4d2bb5178b2632282d2c } from "@ember/template-compiler";
const WelcomeHeader = template_4e558aaaf47e4d2bb5178b2632282d2c(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
